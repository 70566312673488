import React, { Component } from "react";
import { enquireScreen } from "enquire-js";
import { injectIntl } from "gatsby-plugin-intl";

import {
  Banner03DataSource,
  CMND01DataSource,
  FAQDataSource,
  EKYCDataSource,
  CustomersDataSource
} from "../../data/ekyc.data";
import { ContactFormDataSource } from "../../data/home.data";
import loadable from "@loadable/component";

const Layout = loadable(() => import("../../components/Layout"));
const SEO = loadable(() => import("../../components/SEO"));
const Banner = loadable(() => import("../../components/Banner"));

const ContactForm = loadable(() => import("../../components/Home/ContactForm"));
const FAQ = loadable(() => import("../../components/FAQ"));
const CMND01 = loadable(() => import("../../components/CMND/CMND01"));
const EKYC01 = loadable(() => import("../../components/EKYC/EKYC01"));
const Customers = loadable(() => import("../../components/Home/Customers"));

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

class OCRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    const { intl } = this.props;
    return (
      <>
        <SEO
          title={intl.formatMessage({ id: "seo.title_page31" })}
          pathname="/solutions/e-kyc"
          description="eKYC - Giải pháp định danh khách hàng trực tuyến giúp doanh nghiệp tối ưu quy trình làm việc và làm hài lòng khách hàng"
          keywords="eKYC"
        />
        <Layout>
          <Banner
            id="Banner0_3"
            key="Banner0_3"
            dataSource={Banner03DataSource}
            isMobile={this.state.isMobile}
          />
          <Customers
            id="Customers"
            key="Customers"
            dataSource={CustomersDataSource}
            isMobile={this.state.isMobile}
          />
          <EKYC01
            id="EKYC01"
            key="EKYC01"
            dataSource={EKYCDataSource}
            isMobile={this.state.isMobile}
          />
          <CMND01
            id="CMND01"
            key="CMND01"
            dataSource={CMND01DataSource}
            isMobile={this.state.isMobile}
          />
          <ContactForm
            id="ContactForm"
            key="ContactForm"
            dataSource={ContactFormDataSource}
            isMobile={this.state.isMobile}
          />
          <FAQ
            id="FAQ"
            key="FAQ"
            dataSource={FAQDataSource}
            isMobile={this.state.isMobile}
          />
        </Layout>
      </>
    );
  }
}

export default injectIntl(OCRPage);
